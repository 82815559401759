import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Messages = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { conversationID, threadType } = parsedLocation;

  useRedirectHandler(() => {
    if (conversationID) {
      if (threadType === "bulletin") {
        return `https://home.classdojo.com/#/messages/districts/${conversationID}`;
      }
      return `https://home.classdojo.com/#/messages/${conversationID}`;
    }
    return "https://home.classdojo.com/#/messages/";
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Messages;
